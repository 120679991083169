import React, { useState, useEffect } from "react";
import Stack from "@mui/material/Stack";
import { Box, Typography, Autocomplete, TextField, Button, Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { getIvrFlow, getIvrSkillDistribution, updateIvrSkillDistribution } from "../../../services/api-service";
import "./IVR_Skills.css";
import { showErrorNotification, showSuccessNotification } from "../../../components/admin/common/NotiService";
import LoadingScreen from "../../../components/admin/common/OZLoadingScreen/OZLoadingScreen";
import { DropDownIcon } from "../BulkUpdateSettings/AgentSelector";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { NewAdminPages, newAuthPages, newAuthSubAdminAccess } from "../../../services/page-service";

const commonAutocompleteProps = {
  popupIcon: <DropDownIcon />,
  placeholder: "Select Setting",
  size: 'small',
  isOptionEqualToValue: (option, value) => option === value,
  clearIcon: null,
  disableCloseOnSelect: true,
  componentsProps: { paper: { sx: { border: "1px solid #D6D6D7", marginTop: "6px", borderRadius: "8px" } } },
  renderOption: (props, option, { selected, inputValue }) => <Box {...props} sx={{
    paddingLeft: "10px !important",
  }} className="oz-admin-bulk-update-content-body-controls-item-select-option">
    <Typography textOverflow={"ellipsis"} overflow={"hidden"} whiteSpace={"nowrap"} width={"100%"} fontSize={"12px"} fontWeight={400} color={"#3D4C5E"} textTransform={"capitalize"}>
      {typeof option === 'string' ? option : option?.flowName}
    </Typography>
  </Box>,
  renderInput: (params) => <TextField placeholder='Select'
    sx={{
      '& .MuiOutlinedInput-root': { fontSize: "14px", color: "#3D4C5E", borderRadius: '8px', minHeight: "40px" },
      minHeight: "40px"
    }}
    {...params}
  />
}


const IVRSkills = () => {
  const [loading, setLoading] = useState(false);
  const [ivrList, setIvrList] = useState([]);
  const [selectedIvr, setSelectedIvr] = useState(null);
  const [skillDistribution, setSkillDistribution] = useState({});
  // const [originalDistribution, setOriginalDistribution] = useState({});
  const [errors, setErrors] = useState({});
  const [expandedMenu, setExpandedMenu] = useState(null);

  // Check if user has access to edit
  const hasEditAccess = newAuthPages(NewAdminPages.IVR_SKILL_SPLIT_SELECTION) || newAuthSubAdminAccess(NewAdminPages.IVR_SKILL_SPLIT_SELECTION, 'Full') || newAuthSubAdminAccess(NewAdminPages.IVR_SKILL_SPLIT_SELECTION, 'Edit');

  useEffect(() => {
    getIvrList();
  }, []);

  async function getIvrList() {
    setLoading(true);
    try {
      const resp = await getIvrFlow();
      setIvrList(resp.Data);
      setLoading(false);
    } catch (e) {
      showErrorNotification(JSON.stringify(e));
      setIvrList([]);
      setLoading(false);
    }
  }

  const handleIvrChange = async (event, value) => {
    if (!value) {
      setSelectedIvr(null);
      setSkillDistribution({});
      // setOriginalDistribution({});
      setExpandedMenu(null);
      return;
    }

    setSelectedIvr(value);
    setLoading(true);
    try {
      const response = await getIvrSkillDistribution(value.id);
      // Validate and clean the data before setting it
      const cleanedData = {};
      const newErrors = {};

      if (response?.data && typeof response.data === 'object') {
        Object.entries(response.data).forEach(([menu, skills]) => {
          if (typeof skills === 'object' && skills !== null) {
            // Clean the skills data by ensuring all values are valid numbers
            const cleanedSkills = {};
            Object.entries(skills).forEach(([skill, value]) => {
              const numValue = Number(value);
              if (!isNaN(numValue)) {
                cleanedSkills[skill] = numValue.toString();
              }
            });

            // Only include menu if it has valid skills
            if (Object.keys(cleanedSkills).length > 0) {
              cleanedData[menu] = cleanedSkills;

              // Validate total percentage
              const total = Object.values(cleanedSkills).reduce((sum, value) => sum + Number(value || 0), 0);
              if (total !== 100) {
                newErrors[menu] = `Total percentage must be 100%. Current total: ${total}%`;
              }
            }
          }
        });
      }

      setSkillDistribution(cleanedData);
      // setOriginalDistribution(cleanedData);
      setErrors(newErrors);
      // Set the first menu as expanded by default
      setExpandedMenu(Object.keys(cleanedData)[0]);

      if (Object.keys(newErrors).length > 0) {
        showErrorNotification("Some menus have invalid skill distributions. Please review and correct them.");
      }
    } catch (e) {
      showErrorNotification("Failed to fetch skill distribution");
      setSkillDistribution({});
      // setOriginalDistribution({});
      setErrors({});
      setExpandedMenu(null);

    }
    setLoading(false);
  };

  const handleAccordionChange = (menu) => (event, isExpanded) => {
    setExpandedMenu(isExpanded ? menu : null);
  };

  const handleSkillPercentageChange = (menu, skill, value) => {
    if (!hasEditAccess) return;
    const newDistribution = { ...skillDistribution };
    if (!newDistribution[menu]) {
      newDistribution[menu] = {};
    }
    newDistribution[menu][skill] = value;
    setSkillDistribution(newDistribution);

    // Validate total percentage for the menu
    validateMenuPercentages(menu, newDistribution[menu]);
  };

  const validateMenuPercentages = (menu, skills) => {
    const total = Object.values(skills).reduce((sum, value) => sum + Number(value || 0), 0);
    const newErrors = { ...errors };

    if (total !== 100) {
      newErrors[menu] = `Total percentage must be 100%. Current total: ${total}%`;
    } else {
      delete newErrors[menu];
    }

    setErrors(newErrors);
  };

  const handleSave = async () => {
    // Validate all menus
    const newErrors = {};
    Object.entries(skillDistribution).forEach(([menu, skills]) => {
      const total = Object.values(skills).reduce((sum, value) => sum + Number(value || 0), 0);
      if (total !== 100) {
        newErrors[menu] = `Total percentage must be 100%. Current total: ${total}%`;
      }
    });

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      showErrorNotification("Please fix the percentage distribution errors before saving");
      return;
    }

    setLoading(true);
    try {
      await updateIvrSkillDistribution(selectedIvr.id, skillDistribution);
      showSuccessNotification("Skill distribution updated successfully");
    } catch (e) {
      showErrorNotification("Failed to update skill distribution");
    }
    setLoading(false);
  };

  const handleCancel = () => {
    handleIvrChange(null, null);
  };

  return (
    <div className="oz-ivr-skills-container">
      <Typography
        sx={{
          fontweight: '400',
          fontSize: '12px',
          marginBottom: "5px"
        }}
        flexGrow={1}
        alignSelf="center"
      >
        Configurations
      </Typography>
      <Stack direction="row" sx={{ marginBottom: 2 }} spacing={1}>
        <Typography
          sx={{
            fontStyle: "Roboto",
            fontWeight: 600,
            fontSize: "24px",
            lineHeight: "28.13px",
            marginBottom: "4px"
          }}
          flexGrow={1}
          alignSelf="center"
        >
          IVR Skill Split Selection
        </Typography>
      </Stack>

      {loading ? (
        <div style={{ position: 'fixed', top: '0', left: '0', width: '100%', height: '100%', backgroundColor: '0,0,0,0.5', display: 'flex', justifyContent: 'center', alignItems: 'center', zIndex: 1000 }} >
          <LoadingScreen />
        </div>
      ) : (
        <Box sx={{
          backgroundColor: 'white',
          height: 'calc(100vh - 220px)',
          borderRadius: '8px',
          padding: '10px 20px 20px 20px',
          display: 'flex',
          flexDirection: 'column'
        }}>
          <Box sx={{
            position: 'sticky',
            top: 0,
            backgroundColor: 'white',
            zIndex: 1,
            pb: 2,
            borderBottom: '1px solid #e0e0e0'
          }}>
            <Stack spacing={1}>
              <Typography fontSize={"14px"} fontWeight={600} color={"#3D4C5E"}>
                Select IVR Flow
              </Typography>
              <Autocomplete
                {...commonAutocompleteProps}
                options={ivrList}
                getOptionLabel={(option) => option?.flowName ?? ''}
                value={selectedIvr}
                onChange={handleIvrChange}
                isOptionEqualToValue={(option, value) => option?.id === value?.id}
                disableCloseOnSelect={false}
              />
            </Stack>
          </Box>

          <Box sx={{
            flex: 1,
            overflowY: 'auto',
            mt: 2,
            p: 1
          }}>
            <Stack spacing={2}>
              {selectedIvr && <Typography fontSize={"14px"} fontWeight={600} color={"#3D4C5E"}>
                Skill Distribution
              </Typography>}

              {selectedIvr && Object.entries(skillDistribution).map(([menu, skills]) => (
                <Accordion
                  key={menu}
                  expanded={expandedMenu === menu}
                  onChange={handleAccordionChange(menu)}
                  sx={{
                    border: '1px solid',
                    borderColor: errors[menu] ? 'error.main' : '#e0e0e0',
                    borderRadius: '8px !important',
                    '&:before': {
                      display: 'none',
                    },
                    boxShadow: 'none',
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      borderBottom: expandedMenu === menu ? '1px solid #e0e0e0' : 'none',
                      borderRadius: expandedMenu === menu ? '8px 8px 0 0' : '8px',
                      backgroundColor: '#fff',
                      '&:hover': {
                        backgroundColor: '#f8f9fa'
                      },
                      minHeight: '44px !important',
                      '& .MuiAccordionSummary-content': {
                        margin: '12px 0px !important'
                      }
                    }}
                  >
                    <Box>
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: 600,
                          color: errors[menu] ? '#3D4C5E' : '#6b7280',
                          lineHeight: '1.2'
                        }}
                      >
                        {menu}
                      </Typography>
                      {expandedMenu !== menu && <SkillsPreview skills={skills} error={errors[menu]} />}
                    </Box>

                  </AccordionSummary>
                  <AccordionDetails sx={{ p: 1 }}>
                    <Stack spacing={1}>
                      {Object.entries(skills).map(([skill, percentage]) => (
                        <Box
                          key={skill}
                          sx={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 1
                          }}
                        >
                          <Typography
                            sx={{
                              fontSize: '12px',
                              color: '#3D4C5E',
                              flex: 1
                            }}
                          >
                            {skill}
                          </Typography>
                          <TextField
                            size="small"
                            sx={{
                              width: '80px',
                              '& .MuiOutlinedInput-root': {
                                fontSize: "14px",
                                height: '32px'
                              }
                            }}
                            type="number"
                            value={percentage}
                            onChange={(e) => handleSkillPercentageChange(menu, skill, e.target.value)}
                            error={Boolean(errors[menu])}
                            InputProps={{
                              endAdornment: <Typography sx={{ fontSize: '12px', color: '#6b7280' }}>%</Typography>,
                              readOnly: !hasEditAccess
                            }}
                          />
                        </Box>
                      ))}
                      {errors[menu] && (
                        <Typography
                          sx={{
                            color: 'error.main',
                            fontSize: '12px',
                            mt: 1
                          }}
                        >
                          {errors[menu]}
                        </Typography>
                      )}
                    </Stack>
                  </AccordionDetails>
                </Accordion>
              ))}
            </Stack>
          </Box>

          {selectedIvr && Object.keys(skillDistribution).length > 0 && hasEditAccess && (
            <Box sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              gap: 2,
              borderTop: '1px solid #e0e0e0',
              pt: 2,
              mt: 2,
              backgroundColor: 'white',
              position: 'sticky',
              bottom: 0
            }}>
              <Button
                variant="outlined"
                onClick={handleCancel}
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                  borderRadius: '8px'
                }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handleSave}
                disabled={Object.keys(errors).length > 0}
                sx={{
                  textTransform: 'none',
                  fontWeight: 500,
                  borderRadius: '8px'
                }}
              >
                Save Changes
              </Button>
            </Box>
          )}
        </Box>
      )}
    </div>
  );
};

const SkillsPreview = ({ skills, error }) => {
  return (
    <Box>
      <Box sx={{ display: 'flex', gap: 1, flexWrap: 'wrap', mt: 0.4 }}>
        {Object.keys(skills).map((skill, index, array) => (
          <Typography key={skill} sx={{ fontSize: '12px', color: '#6b7280' }}>
            {skill}: {skills[skill]}%{index !== array.length - 1 ? ', ' : ''}
          </Typography>
        ))}
      </Box>
      {error && <Typography sx={{ fontSize: '12px', color: 'error.main', mt: 0.4 }}>{error}</Typography>}
    </Box>
  );
};

export default IVRSkills;
